'use client';

import { useEffect } from 'react';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Radio,
  RadioGroup,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@headlessui/react';
//import { products_url as url } from '../utils/constants';

import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useProductsContext } from '../context/products_context';
import { Breadcrumb, Error } from '../components';
import Loading from '../components/Loading';
const url = process.env.REACT_APP_PRODUCTS_URL;
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const SingleProductPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    single_product_loading: loading,
    single_product_error: error,
    single_product: product,
    fetchSingleProduct,
  } = useProductsContext();

  useEffect(() => {
    fetchSingleProduct(`${url}${id}`);
    // eslint-disable-next-line
  }, [id]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return (
      <div className='grid grid-cols-1 mb-80'>
        <Error />
      </div>
    );
  }
  const { title, description, company, details } = product;
  return (
    <div className='bg-white'>
      <Breadcrumb title={title} product={product} />
      <div className='mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8'>
        <div className='lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8'>
          {/* Image gallery */}
          <TabGroup className='flex flex-col-reverse'>
            {/* Image selector */}
            <div className='mx-auto mt-6 w-full max-w-2xl sm:block lg:max-w-none'>
              <TabList className='grid grid-cols-4 gap-6'>
                {product?.images?.map((image) => (
                  <Tab
                    key={image.id}
                    className='group relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-2'
                  >
                    <span className='sr-only'>{title}</span>
                    <span className='absolute inset-0 overflow-hidden rounded-md'>
                      <img
                        alt={title}
                        src={image.url}
                        className='h-full w-full object-scale-down object-center'
                      />
                    </span>
                    <span
                      aria-hidden='true'
                      className='pointer-events-none absolute inset-0 rounded-md ring-2 ring-transparent ring-offset-2 group-data-[selected]:ring-amber-400'
                    />
                  </Tab>
                ))}
              </TabList>
            </div>

            <TabPanels className='aspect-h-1 aspect-w-1 w-full'>
              {product?.images?.map((image) => (
                <TabPanel key={image.id}>
                  <img
                    alt={title}
                    src={image.url}
                    className='h-full w-full object-scale-down object-center sm:rounded-lg'
                  />
                </TabPanel>
              ))}
            </TabPanels>
          </TabGroup>
          {/* Product info */}
          <div className='mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0'>
            <h1 className='text-3xl font-bold tracking-tight text-gray-900'>
              {title}
            </h1>
            <div className='mt-3'>
              <h2 className='sr-only'>Product information</h2>
              <p className='text-l tracking-tight text-gray-900'>
                Εταιρεία: {company}
              </p>
            </div>
            <div className='mt-6'>
              <h3 className='sr-only'>Περιγραφή</h3>

              <div
                dangerouslySetInnerHTML={{ __html: description }}
                className='space-y-6 text-base text-gray-700'
              />
            </div>

            <div className='mt-10 flex'>
              <Link
                to={'/contact'}
                className='flex max-w-xs flex-1 items-center justify-center rounded-md border border-transparent bg-amber-400 px-8 py-3 text-base font-medium text-white hover:bg-amber-300 focus:outline-none focus:ring-2 focus:ring-amber-400 focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full'
              >
                Επικοινωνήστε μαζί μας
              </Link>
            </div>

            <section aria-labelledby='details-heading' className='mt-12'>
              <h2 id='details-heading' className='sr-only'>
                Additional details
              </h2>

              <div className='divide-y divide-gray-200 border-t'>
                <Disclosure as='div'>
                  <h3>
                    <DisclosureButton className='group relative flex w-full items-center justify-between py-6 text-left'>
                      <span className='text-sm font-medium text-gray-900 group-data-[open]:text-amber-400'>
                        Χαρακτηριστικά
                      </span>
                      <span className='ml-6 flex items-center'>
                        <PlusIcon
                          aria-hidden='true'
                          className='block h-6 w-6 text-gray-400 group-hover:text-gray-500 group-data-[open]:hidden'
                        />
                        <MinusIcon
                          aria-hidden='true'
                          className='hidden h-6 w-6 text-amber-400 group-hover:text-amber-300 group-data-[open]:block'
                        />
                      </span>
                    </DisclosureButton>
                  </h3>
                  <DisclosurePanel className='prose prose-sm pb-6'>
                    <ul role='list'>
                      {details?.map((item) => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                  </DisclosurePanel>
                </Disclosure>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProductPage;
