import React from 'react';
import Product from './Product';
import { useFilterContext } from '../context/filter_context';
import { useProductsContext } from '../context/products_context';

// const products = [
//   {
//     _id: 1,
//     name: 'Δραπανοκατσάβιδο Μπαταρίας Ryobi',
//     price: '56',
//     rating: 4,
//     details: [
//       {
//         name: 'Features',
//         items: [
//           'Multiple strap configurations',
//           'Spacious interior with top zip',
//           'Leather handle and tabs',
//           'Interior dividers',
//           'Stainless strap loops',
//           'Double stitched construction',
//           'Water-resistant',
//         ],
//       },
//       // More sections...
//     ],
//     colors: [
//       {
//         name: 'Washed Black',
//         bgColor: 'bg-gray-700',
//         selectedColor: 'ring-gray-700',
//       },
//       { name: 'White', bgColor: 'bg-white', selectedColor: 'ring-gray-400' },
//       {
//         name: 'Washed Gray',
//         bgColor: 'bg-gray-500',
//         selectedColor: 'ring-gray-500',
//       },
//     ],
//     images: [
//       {
//         id: 1,
//         name: 'Angled view',
//         src: 'https://cdn.pixabay.com/photo/2018/09/03/11/21/drill-3650994_1280.png',
//         alt: 'Angled front view with bag zipped and handles upright.',
//       },
//       // More images...
//     ],
//     description:
//       'Το Ryobi 41314 είναι ένα δραπανοκατσάβιδο για ημί-επαγγελματίες με μεγάλη διάρκεια λειτουργίας και υψηλό δείκτη αξιοπιστίας. Διατίθεται μαζί με μπαταρία, ταχυφορτιστή και βαλίτσα μεταφοράς.',
//     options: '8 colors',
//     imageSrc:
//       'https://cdn.pixabay.com/photo/2018/09/03/11/21/drill-3650994_1280.png',
//     imageAlt:
//       'Eight shirts arranged on table in black, olive, grey, blue, white, red, mustard, and green.',
//   },
//   {
//     _id: 2,
//     name: 'Σέγα Dewalt',
//     price: '32',
//     rating: 4,
//     details: [
//       {
//         name: 'Features',
//         items: [
//           'Multiple strap configurations',
//           'Spacious interior with top zip',
//           'Leather handle and tabs',
//           'Interior dividers',
//           'Stainless strap loops',
//           'Double stitched construction',
//           'Water-resistant',
//         ],
//       },
//       // More sections...
//     ],
//     colors: [
//       {
//         name: 'Washed Black',
//         bgColor: 'bg-gray-700',
//         selectedColor: 'ring-gray-700',
//       },
//       { name: 'White', bgColor: 'bg-white', selectedColor: 'ring-gray-400' },
//       {
//         name: 'Washed Gray',
//         bgColor: 'bg-gray-500',
//         selectedColor: 'ring-gray-500',
//       },
//     ],
//     images: [
//       {
//         id: 1,
//         name: 'Angled view',
//         src: 'https://cdn.pixabay.com/photo/2019/11/15/13/22/battery-4628419_1280.jpg',
//         alt: 'Angled front view with bag zipped and handles upright.',
//       },
//       // More images...
//     ],
//     description:
//       'Το Dewalt 413145 είναι μια σέγα για ημί-επαγγελματίες με υψηλό δείκτη αξιοπιστίας. Διατίθεται μαζί με ταχυφορτιστή και βαλίτσα μεταφοράς.',
//     options: 'Black',
//     imageSrc:
//       'https://cdn.pixabay.com/photo/2019/11/15/13/22/battery-4628419_1280.jpg',
//     imageAlt: 'Front of plain black t-shirt.',
//   },
//   {
//     _id: 3,
//     name: 'Τρυπάνι Abc123',
//     price: '45',
//     rating: 4,
//     details: [
//       {
//         name: 'Features',
//         items: [
//           'Multiple strap configurations',
//           'Spacious interior with top zip',
//           'Leather handle and tabs',
//           'Interior dividers',
//           'Stainless strap loops',
//           'Double stitched construction',
//           'Water-resistant',
//         ],
//       },
//       // More sections...
//     ],
//     colors: [
//       {
//         name: 'Washed Black',
//         bgColor: 'bg-gray-700',
//         selectedColor: 'ring-gray-700',
//       },
//       { name: 'White', bgColor: 'bg-white', selectedColor: 'ring-gray-400' },
//       {
//         name: 'Washed Gray',
//         bgColor: 'bg-gray-500',
//         selectedColor: 'ring-gray-500',
//       },
//     ],
//     images: [
//       {
//         id: 1,
//         name: 'Angled view',
//         src: 'https://cdn.pixabay.com/photo/2020/04/10/10/46/bit-5025182_1280.jpg',
//         alt: 'Angled front view with bag zipped and handles upright.',
//       },
//       // More images...
//     ],
//     description:
//       'Το τρυπάνι Abc123 απευθύνεται σε επαγγελματίες το οποίο ανταπεξέρχετε ευκολα στις δύσκολες εργασίες.',
//     options: 'Black',
//     imageSrc:
//       'https://cdn.pixabay.com/photo/2020/04/10/10/46/bit-5025182_1280.jpg',
//     imageAlt: 'Front of plain black t-shirt.',
//   },
//   {
//     _id: 4,
//     name: 'Μπετονιέρα Kittory',
//     price: '80',
//     rating: 4,
//     details: [
//       {
//         name: 'Features',
//         items: [
//           'Multiple strap configurations',
//           'Spacious interior with top zip',
//           'Leather handle and tabs',
//           'Interior dividers',
//           'Stainless strap loops',
//           'Double stitched construction',
//           'Water-resistant',
//         ],
//       },
//       // More sections...
//     ],
//     colors: [
//       {
//         name: 'Washed Black',
//         bgColor: 'bg-gray-700',
//         selectedColor: 'ring-gray-700',
//       },
//       { name: 'White', bgColor: 'bg-white', selectedColor: 'ring-gray-400' },
//       {
//         name: 'Washed Gray',
//         bgColor: 'bg-gray-500',
//         selectedColor: 'ring-gray-500',
//       },
//     ],
//     images: [
//       {
//         id: 1,
//         name: 'Angled view',
//         src: 'ttps://cdn.pixabay.com/photo/2020/01/22/01/57/betonka-4784341_1280.jpg',
//         alt: 'Angled front view with bag zipped and handles upright.',
//       },
//       // More images...
//     ],
//     description:
//       'Επαγγελματική μπετονιέρα Kittory με προηγμένο σύστημα τεχνολογίας.',
//     options: 'Black',
//     imageSrc:
//       'https://cdn.pixabay.com/photo/2020/01/22/01/57/betonka-4784341_1280.jpg',
//     imageAlt: 'Front of plain black t-shirt.',
//   },
//   {
//     _id: 5,
//     name: 'Καροτιέρα με Βάση',
//     price: '95',
//     rating: 4,
//     details: [
//       {
//         name: 'Features',
//         items: [
//           'Multiple strap configurations',
//           'Spacious interior with top zip',
//           'Leather handle and tabs',
//           'Interior dividers',
//           'Stainless strap loops',
//           'Double stitched construction',
//           'Water-resistant',
//         ],
//       },
//       // More sections...
//     ],
//     colors: [
//       {
//         name: 'Washed Black',
//         bgColor: 'bg-gray-700',
//         selectedColor: 'ring-gray-700',
//       },
//       { name: 'White', bgColor: 'bg-white', selectedColor: 'ring-gray-400' },
//       {
//         name: 'Washed Gray',
//         bgColor: 'bg-gray-500',
//         selectedColor: 'ring-gray-500',
//       },
//     ],
//     images: [
//       {
//         id: 1,
//         name: 'Angled view',
//         src: 'https://caykentools.com/wp-content/uploads/2021/01/Dk-450-1-1-scaled.jpg',
//         alt: 'Angled front view with bag zipped and handles upright.',
//       },
//       // More images...
//     ],
//     description: 'Καροτιέρα με βάση, με βέλτιστη ταχύτητα εκκίνησης.',
//     options: 'Black',
//     imageSrc:
//       'https://caykentools.com/wp-content/uploads/2021/01/Dk-450-1-1-scaled.jpg',
//     imageAlt: 'Front of plain black t-shirt.',
//   },
//   // More products...
// ];

const ProductList = () => {
  const { filtered_products: products } = useFilterContext();
  const { products_loading: loading, products_error: error } =
    useProductsContext();
  return (
    <div className='grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:gap-x-8 xl:grid-cols-3'>
      {products.map((product) => {
        return <Product key={product._id} {...product} />;
      })}
    </div>
  );
};

export default ProductList;
