'use client';
import React, { useState } from 'react';
import { FiTruck, FiTool, FiUsers, FiSettings } from 'react-icons/fi';
import { VscWorkspaceTrusted } from 'react-icons/vsc';
import { FaHelmetSafety } from 'react-icons/fa6';
import { RiCustomerService2Fill } from 'react-icons/ri';

import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import photo1 from '../assets/sp4.jpg';
import photo2 from '../assets/sp2.jpg';
import photo3 from '../assets/sp3.jpg';
import photo4 from '../assets/sp5.jpg';
import { TbArrowBearRight2 } from 'react-icons/tb';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useProductsContext } from '../context/products_context';

const features = [
  {
    name: 'Αξιοπιστία',
    description:
      'Γρήγορη και αξιόπιστη ενοικίαση μηχανημάτων έργου: Παρέχουμε μια ευρεία γκάμα σύγχρονων και καλά συντηρημένων μηχανημάτων που υποστηρίζουν αποτελεσματικά και έγκαιρα τα κατασκευαστικά σας έργα.',
    icon: VscWorkspaceTrusted,
  },
  {
    name: 'Ευελιξία',
    description:
      'Ευέλικτες επιλογές ενοικίασης για κάθε ανάγκη: Είτε πρόκειται για ημερήσια ενοικίαση είτε για μακροχρόνιες λύσεις, προσφέρουμε ευέλικτα πακέτα που ταιριάζουν απόλυτα στις ανάγκες σας.',
    icon: TbArrowBearRight2,
  },
  {
    name: 'Εξυπηρέτηση',
    description:
      'Ολοκληρωμένη εξυπηρέτηση και τεχνική υποστήριξη: Η ομάδα μας είναι πάντα διαθέσιμη να σας βοηθήσει στην επιλογή του κατάλληλου εξοπλισμού και να απαντήσει σε τεχνικά ζητήματα.',
    icon: RiCustomerService2Fill,
  },
  {
    name: 'Προστασία',
    description:
      'Ασφάλεια και ποιότητα πάνω απ’ όλα: Όλα τα μηχανήματα ελέγχονται τακτικά, ώστε να εξασφαλίζουν τη μέγιστη ασφάλεια και απόδοση.',
    icon: FaHelmetSafety,
  },
];

const slides = [
  {
    title: 'Ενοικίαση Εξοπλισμού',
    description: 'Με την αξιοπιστία της HILTI',
    image: photo1,
    button: {
      href: '/contact',
      title: 'Εποικινωνία',
    },
  },
  {
    title: 'Ενοικίαση Εξοπλισμού Κατασκευών',
    description:
      'Καλωσορίσατε στην κορυφαία επιλογή ενοικίασης εξοπλισμού κατασκευών!',
    image: photo2,
    button: {
      href: '/contact',
      title: 'Εποικινωνία',
    },
  },
  {
    title: 'Ενοικίαση Εξοπλισμού Κατασκευών',
    description:
      'Καλωσορίσατε στην κορυφαία επιλογή ενοικίασης εξοπλισμού κατασκευών!',
    image: photo3,
    button: {
      href: '/contact',
      title: 'Εποικινωνία',
    },
  },
  {
    title: 'Τεράστια Γκάμα Εξοπλισμού',
    description:
      'Καλωσορίσατε στην κορυφαία επιλογή ενοικίασης εξοπλισμού κατασκευών!',
    image: photo4,
    button: {
      href: '/contact',
      title: 'Εποικινωνία',
    },
  },
];

const HomePage = () => {
  const { featureProducts: products, feature_products_loading } =
    useProductsContext();
  return (
    <div className='bg-white'>
      <main>
        {/* Hero section */}
        <div className='relative bg-gray-900'>
          <Swiper
            centeredSlides={true}
            loop={true}
            autoplay={{
              delay: 4500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className='mySwiper'
            style={{
              '--swiper-pagination-color': '#fbbf24',
              '--swiper-pagination-bullet-inactive-color': '#999999',
              '--swiper-pagination-bullet-inactive-opacity': '1',
              '--swiper-pagination-bullet-size': '16px',
              '--swiper-pagination-bullet-horizontal-gap': '6px',
              '--swiper-navigation-color': '#4b5563',
            }}
          >
            {slides.map((slide) => (
              <SwiperSlide>
                ...
                {/* Decorative image and overlay */}
                <div
                  aria-hidden='true'
                  className='absolute inset-0 overflow-hidden'
                >
                  <img
                    alt={slide.button}
                    src={slide.image}
                    className='h-full w-full object-cover object-center'
                  />
                </div>
                <div
                  aria-hidden='true'
                  className='absolute inset-0 bg-gray-900 opacity-50'
                />
                <div className='relative mx-auto flex max-w-3xl flex-col items-center px-6 py-32 text-center sm:py-64 lg:px-0'>
                  <h1 className='text-4xl font-bold tracking-tight text-white lg:text-6xl'>
                    {slide.title}
                  </h1>
                  <p className='mt-4 text-xl text-white'>{slide.description}</p>
                  <Link
                    to={slide.button.href}
                    className='mt-8 inline-block rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-gray-900 hover:bg-gray-100'
                  >
                    {slide.button.title}
                  </Link>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {/* Trending Products */}
        <section aria-labelledby='trending-heading'>
          <div className='mx-auto max-w-7xl px-4 py-24 sm:px-6 sm:py-32 lg:px-8 lg:pt-32'>
            <div className='md:flex md:items-center md:justify-between'>
              <h2
                id='favorites-heading'
                className='text-2xl font-bold tracking-tight text-gray-900'
              >
                Πρόσφατα Προϊόντα
              </h2>
            </div>

            <div className='mt-6 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-0 lg:gap-x-8'>
              {!feature_products_loading ? (
                products?.map((product) => (
                  <div key={product._id} className='group relative'>
                    <div className='h-56 w-full overflow-hidden rounded-md group-hover:opacity-75 lg:h-72 xl:h-80'>
                      <img
                        alt={product.title}
                        src={product.images[0].url}
                        className='h-full w-full object-scale-down object-center'
                      />
                    </div>
                    <h3 className='mt-4 text-sm text-gray-700'>
                      <Link to={`products/${product._id}`}>
                        <span className='absolute inset-0' />
                        {product.title}
                      </Link>
                    </h3>
                    {/* <p className='mt-1 text-sm font-medium text-gray-900'>
                    &#8364;{product.price}/εβδ.
                  </p> */}
                  </div>
                ))
              ) : (
                <div className='mt-5'>
                  <div role='status'>
                    <svg
                      aria-hidden='true'
                      className='inline w-14 h-14 text-gray-200 animate-spin dark:text-gray-600 fill-amber-400 '
                      viewBox='0 0 100 101'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                        fill='currentColor'
                      />
                      <path
                        d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                        fill='currentFill'
                      />
                    </svg>
                    <span className='sr-only'>Loading...</span>
                  </div>
                </div>
              )}
            </div>

            <div className='mt-8 text-sm md:hidden'>
              {/* <a
                href='#'
                className='font-medium text-indigo-600 hover:text-indigo-500'
              >
                Shop the collection
                <span aria-hidden='true'> &rarr;</span>
              </a> */}
            </div>
          </div>
        </section>
        {/* Services section */}
        <section
          aria-labelledby='perks-heading'
          className='border-t border-gray-200 bg-gray-50'
        >
          <h2 id='perks-heading' className='sr-only'>
            Our perks
          </h2>
          <div className='mx-auto max-w-7xl px-4 py-24 sm:px-6 sm:py-32 lg:px-8'>
            <dl className='grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16'>
              {features.map((feature) => (
                <div key={feature.name} className='relative pl-16'>
                  <dt className='text-base font-semibold leading-7 text-gray-900'>
                    <div className='absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-amber-400'>
                      <feature.icon
                        aria-hidden='true'
                        className='h-6 w-6 text-white'
                      />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className='mt-2 text-base leading-7 text-gray-600'>
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </section>
      </main>
    </div>
  );
};
export default HomePage;
