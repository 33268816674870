import { Fragment } from 'react';
import {
  Dialog,
  DialogPanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import {
  Bars3Icon,
  ChevronDownIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import logo from '../assets/logo-free.png';
// import CartButtons from './CartButtons';
// import AuthenticationButtons from './AuthenticationButtons';
// import UserMenu from './UserMenu';
// import { useUserContext } from '../context/user_context';
import { useProductsContext } from '../context/products_context';
import { navigation } from '../utils/constants';
import MobileNavbarLinks from './MobileNavbarLinks';
import { Link } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Nav = () => {
  //   const { myUser } = useUserContext();
  //const { categories } = useProductsContext();

  // navigation.categories[0].sections.push(...categories);
  const { isSidebarOpen, closeSidebar, openSidebar, categories_loading } =
    useProductsContext();

  return (
    <div className='bg-white'>
      {/* Mobile menu */}
      <Transition show={isSidebarOpen} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-40 lg:hidden'
          onClose={closeSidebar}
        >
          <TransitionChild
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </TransitionChild>

          <div className='fixed inset-0 z-40 flex'>
            <TransitionChild
              as={Fragment}
              enter='transition ease-in-out duration-300 transform'
              enterFrom='-translate-x-full'
              enterTo='translate-x-0'
              leave='transition ease-in-out duration-300 transform'
              leaveFrom='translate-x-0'
              leaveTo='-translate-x-full'
            >
              <DialogPanel className='relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl'>
                <div className='flex px-4 pb-2 pt-5'>
                  <button
                    type='button'
                    className='relative -m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400'
                    onClick={closeSidebar}
                  >
                    <span className='absolute -inset-0.5' />
                    <span className='sr-only'>Close menu</span>
                    <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </div>

                {/* Mobile Navbar Links */}
                <MobileNavbarLinks />
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>

      <header className='relative bg-white'>
        {/* <p className='flex h-10 items-center justify-center bg-indigo-600 px-4 text-sm font-medium text-white sm:px-6 lg:px-8'>
          Get free delivery on orders over $100
        </p> */}

        <nav
          aria-label='Top'
          className='mx-auto max-w-9xl px-4 sm:px-6 lg:px-8'
        >
          <div className='border-b border-gray-200'>
            <div className='flex h-16 items-center'>
              <button
                type='button'
                className='relative rounded-md bg-white p-2 text-gray-400 lg:hidden'
                onClick={openSidebar}
              >
                <span className='absolute -inset-0.5' />
                <span className='sr-only'>Open menu</span>
                <Bars3Icon className='h-6 w-6' aria-hidden='true' />
              </button>

              {/* Logo */}
              <div className='ml-4 flex lg:ml-0'>
                <Link to='/'>
                  <span className='sr-only'>Your Company</span>
                  <img className='h-24 w-auto' src={logo} alt='' />
                </Link>
              </div>

              {/* Flyout menus */}
              <PopoverGroup className='hidden lg:ml-8 lg:block lg:self-stretch'>
                <div className='flex h-full space-x-8'>
                  <Link
                    key='home'
                    to={'/'}
                    className='flex items-center text-sm font-medium text-gray-700 hover:text-amber-400'
                  >
                    Αρχική
                  </Link>
                  {navigation?.categories?.map((category) => (
                    <Popover key={category.name} className='flex'>
                      {({ open }) => (
                        <>
                          <div className='relative flex'>
                            <PopoverButton
                              className={classNames(
                                open
                                  ? 'border-amber-400 text-amber-400'
                                  : 'border-transparent text-gray-700 hover:text-amber-400',
                                'relative z-10 -mb-px flex items-center border-b-2 pt-px text-sm font-medium transition-colors duration-200 ease-out'
                              )}
                            >
                              {category.name}
                              <span className='w-4 h-4'>
                                <ChevronDownIcon
                                  className={`${
                                    open ? 'rotate-180 transform' : ''
                                  }`}
                                />
                              </span>
                            </PopoverButton>
                          </div>

                          <Transition
                            as={Fragment}
                            enter='transition ease-out duration-200'
                            enterFrom='opacity-0'
                            enterTo='opacity-100'
                            leave='transition ease-in duration-150'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                          >
                            <PopoverPanel className='absolute inset-x-0 top-full text-sm text-gray-500 z-50'>
                              {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                              <div
                                className='absolute inset-0 top-1/2 bg-white shadow'
                                aria-hidden='true'
                              />

                              <div className='relative bg-white'>
                                <div className='mx-auto max-w-7xl px-8'>
                                  <div className='grid grid-cols-2 gap-x-8 gap-y-10 py-16'>
                                    <div className='row-start-1 grid grid-cols-3 gap-x-8 gap-y-10 text-sm'>
                                      {!categories_loading ? (
                                        category?.sections?.map((section) => (
                                          <div key={section.name}>
                                            <p
                                              id={`${section.name}-heading`}
                                              className='font-medium text-gray-900'
                                            >
                                              {section.name}
                                            </p>
                                            <ul
                                              aria-labelledby={`${section.name}-heading`}
                                              className='mt-6 space-y-6 sm:mt-4 sm:space-y-4'
                                            >
                                              {section?.subcategories?.map(
                                                (item) => (
                                                  <li
                                                    key={item.name}
                                                    className='flex'
                                                  >
                                                    <Link
                                                      to={`/products/${section.name}/${item.name}`}
                                                      className='hover:text-amber-400'
                                                    >
                                                      <PopoverButton>
                                                        {item.name}
                                                      </PopoverButton>
                                                    </Link>
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </div>
                                        ))
                                      ) : (
                                        <div>
                                          <div role='status'>
                                            <svg
                                              aria-hidden='true'
                                              className='inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-amber-400'
                                              viewBox='0 0 100 101'
                                              fill='none'
                                              xmlns='http://www.w3.org/2000/svg'
                                            >
                                              <path
                                                d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                                                fill='currentColor'
                                              />
                                              <path
                                                d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                                                fill='currentFill'
                                              />
                                            </svg>
                                            <span className='sr-only'>
                                              Loading...
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </PopoverPanel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  ))}

                  {navigation.pages.map((page) => (
                    <Link
                      key={page.name}
                      to={page.href}
                      className='flex items-center text-sm font-medium text-gray-700 hover:text-amber-400'
                    >
                      {page.name}
                    </Link>
                  ))}
                </div>
              </PopoverGroup>

              <div className='ml-auto flex items-center'>
                {/* Search */}
                {/* <div className='flex lg:ml-6'>
                  <a href='#' className='p-2 text-gray-400 hover:text-gray-500'>
                    <span className='sr-only'>Search</span>
                    <MagnifyingGlassIcon
                      className='h-6 w-6'
                      aria-hidden='true'
                    />
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};
export default Nav;
