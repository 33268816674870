import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
  Home,
  Error,
  Contact,
  About,
  Services,
  Products,
  SingleProduct,
} from './pages';
import { Footer, Navbar, ScrollToTop } from './components';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/company' element={<About />} />
        <Route path='/services' element={<Services />} />
        <Route path='products/:category/:subcategory' element={<Products />} />
        <Route path='products/:id' element={<SingleProduct />} />
        <Route path='*' element={<Error />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
