export const SIDEBAR_OPEN = 'SIDEBAR_OPEN';
export const SIDEBAR_CLOSE = 'SIDEBAR_CLOSE';
export const MOBILE_FILTERS_SIDEBAR_CLOSE = 'MOBILE_FILTERS_SIDEBAR_CLOSE';
export const MOBILE_FILTERS_SIDEBAR_OPEN = 'MOBILE_FILTERS_SIDEBAR_OPEN';

export const LOAD_PRODUCTS = 'LOAD_PRODUCTS';
export const SET_GRIDVIEW = 'SET_GRIDVIEW';
export const SET_LISTVIEW = 'SET_LISTVIEW';
export const UPDATE_SORT = 'UPDATE_SORT';
export const SORT_PRODUCTS = 'SORT_PRODUCTS';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const FILTER_PRODUCTS = 'FILTER_PRODUCTS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';

export const GET_PRODUCTS_BEGIN = 'GET_PRODUCTS_BEGIN';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';
export const GET_SINGLE_PRODUCT_BEGIN = 'GET_SINGLE_PRODUCT_BEGIN';
export const GET_SINGLE_PRODUCT_SUCCESS = 'GET_SINGLE_PRODUCT_SUCCESS';
export const GET_SINGLE_PRODUCT_ERROR = 'GET_SINGLE_PRODUCT_ERROR';

export const GET_CATEGORIES_BEGIN = 'GET_CATEGORIES_BEGIN';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';

export const GET_FEATURE_PRODUCTS_BEGIN = 'GET_FEATURE_PRODUCTS_BEGIN';
export const GET_FEATURE_PRODUCTS_SUCCESS = 'GET_FEATURE_PRODUCTS_SUCCESS';
export const GET_FEATURE_PRODUCTS_ERROR = 'GET_FEATURE_PRODUCTS_ERROR';
