import React from 'react';
import { Link } from 'react-router-dom';
const Error = () => {
  return (
    <main className='mx-auto max-w-2xl px-4 lg:max-w-7xl lg:px-8'>
      <div className='pb-24 pt-12 lg:grid lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4'>
        <section
          aria-labelledby='product-heading'
          className='mt-6 lg:col-span-2 lg:mt-0 xl:col-span-3'
        >
          <div className='justify-items-center text-center'>
            <img
              className='h-56 w-96 object-cover'
              src='/images/industry-4-0.svg'
              alt='error'
            />
            <h1 className='text-3xl mb-5'>Υπήρξε τεχνικό πρόβλημα!</h1>
            <Link
              type='button'
              className=' mt-10 rounded-md bg-amber-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-amber-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-400'
              to={'/'}
            >
              Επιστροφή στην Αρχική
            </Link>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Error;
