import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useProductsContext } from '../context/products_context';
import { useFilterContext } from '../context/filter_context';
import { getUniqueValues } from '../utils/helpers';
import { CiSearch } from 'react-icons/ci';
import { SlTrash } from 'react-icons/sl';

// const filters = [
//   {
//     id: 'type',
//     name: 'Τύπος',
//     options: [
//       { value: 'new-arrivals', label: 'Ηλεκτρικό' },
//       { value: 'tees', label: 'Μπαταρίας' },
//     ],
//   },
//   {
//     id: 'brand',
//     name: 'Μάρκα',
//     options: [
//       { value: 'xs', label: 'Dewalt' },
//       { value: 's', label: 'Boss' },
//       { value: 'm', label: 'Ryobi' },
//     ],
//   },
// ];

const MobileFilters = () => {
  const {
    filters: { text, company },
    updateFilters,
    all_products,
    clearFilters,
  } = useFilterContext();

  const companies = getUniqueValues(all_products, 'company');
  const { isMobileFiltersSidebarOpen, closeMobileFilters } =
    useProductsContext();
  return (
    <Dialog
      open={isMobileFiltersSidebarOpen}
      onClose={closeMobileFilters}
      className='relative z-40 lg:hidden'
    >
      <DialogBackdrop
        transition
        className='fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0'
      />

      <div className='fixed inset-0 z-40 flex'>
        <DialogPanel
          transition
          className='relative ml-auto flex h-full w-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full'
        >
          <div className='flex items-center justify-between px-4'>
            <h2 className='text-lg font-medium text-gray-900'>Φίλτρα</h2>
            <button
              type='button'
              onClick={closeMobileFilters}
              className='relative -mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-500'
            >
              <span className='absolute -inset-0.5' />
              <span className='sr-only'>Close menu</span>
              <XMarkIcon aria-hidden='true' className='h-6 w-6' />
            </button>
          </div>

          {/* Filters */}
          <form className='mt-4' onSubmit={(e) => e.preventDefault()}>
            <Disclosure
              // key={section.name}
              as='div'
              className='border-t border-gray-200 pb-4 pt-4'
            >
              <div className='mt-2 mb-3 p-3'>
                <label
                  htmlFor='search'
                  className='block text-sm/6 font-medium text-gray-900'
                >
                  Αναζήτηση
                </label>
                <div className='relative mt-2 flex items-center'>
                  <input
                    name='text'
                    type='text'
                    value={text}
                    placeholder='Αναζήτηση'
                    onChange={updateFilters}
                    className='block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-400 sm:text-sm/6'
                  />
                  <div className='absolute inset-y-0 right-0 flex py-1.5 pr-1.5'>
                    <kbd className='inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400'>
                      <CiSearch />
                    </kbd>
                  </div>
                </div>
              </div>
              <fieldset>
                <legend className='w-full px-2'>
                  <DisclosureButton className='group flex w-full items-center justify-between p-2 text-gray-400 hover:text-gray-500'>
                    <span className='text-sm font-medium text-gray-900'>
                      Εταιρεία
                    </span>
                    <span className='ml-6 flex h-7 items-center'>
                      <ChevronDownIcon
                        aria-hidden='true'
                        className='h-5 w-5 rotate-0 transform group-data-[open]:-rotate-180'
                      />
                    </span>
                  </DisclosureButton>
                </legend>
                <DisclosurePanel className='px-4 pb-2 pt-4'>
                  <div className='space-y-6'>
                    {companies.map((c, index) => (
                      <div key={index} className='flex items-center'>
                        <input
                          name='company'
                          id={index}
                          type='radio'
                          value={c}
                          checked={company === c}
                          onChange={updateFilters}
                          className='h-4 w-4 rounded border-gray-300 text-amber-400 focus:ring-amber-400'
                        />
                        <label
                          htmlFor={`${index}-mobile`}
                          className='ml-3 text-sm text-gray-500'
                        >
                          {c}
                        </label>
                      </div>
                    ))}
                  </div>
                </DisclosurePanel>
              </fieldset>
            </Disclosure>
            <div className='p-3'>
              <button
                type='button'
                className='inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600'
                onClick={clearFilters}
              >
                <SlTrash aria-hidden='true' className='-ml-0.5 size-5' />
                Εκκαθάριση
              </button>
            </div>
          </form>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default MobileFilters;
