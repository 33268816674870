import { useParams } from 'react-router-dom';
import {
  Breadcrumb,
  Error,
  Filters,
  Loading,
  MobileFilters,
  ProductList,
} from '../components';
import { useProductsContext } from '../context/products_context';
import { useEffect } from 'react';
//import { products_url as url } from '../utils/constants';
import { useFilterContext } from '../context/filter_context';
const url = process.env.REACT_APP_PRODUCTS_URL;
const ProductsPage = () => {
  const {
    products_loading: loading,
    products_error: error,
    fetchProducts,
  } = useProductsContext();
  const { filtered_products, clearFilters } = useFilterContext();
  const { category, subcategory } = useParams();

  useEffect(() => {
    clearFilters();
    fetchProducts(`${url}${category}/${subcategory}`);

    // eslint-disable-next-line
  }, [category, subcategory]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return (
      <div className='grid grid-cols-1 mb-80'>
        <Error />
      </div>
    );
  }

  if (filtered_products?.length < 1) {
    return (
      <div className='bg-white'>
        <div>
          {/* Mobile filter dialog */}
          <MobileFilters />
          {/*  */}
          {/* Breadcrumb */}
          <Breadcrumb category={category} subcategory={subcategory} />
          {/*  */}
          <main className='mx-auto max-w-2xl px-4 lg:max-w-7xl lg:px-8'>
            <div className='border-b border-gray-200 pb-10 pt-24'>
              <h1 className='text-4xl font-bold tracking-tight text-gray-900'>
                Προϊόντα
              </h1>
              {/* <p className='mt-4 text-base text-gray-500'>
                Lorem ipsum dolor sit amet consectetur adipisicing.
              </p> */}
            </div>

            <div className='pb-24 pt-12 lg:grid lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4'>
              {/* Filters */}
              <Filters />
              {/*  */}

              <section
                aria-labelledby='product-heading'
                className='mt-6 lg:col-span-2 lg:mt-0 xl:col-span-3'
              >
                <h2 id='product-heading' className='sr-only'>
                  Products
                </h2>

                <div className='justify-items-center text-center'>
                  <img
                    className='h-56 w-96 object-cover'
                    src='/images/industry-4-0.svg'
                    alt=''
                  />
                  <h1 className='text-3xl'>
                    Δεν Βρέθηκαν Προϊόντα με τα Επιλεγμένα Φίλτρα!
                  </h1>
                </div>

                {/*  */}
              </section>
            </div>
          </main>
        </div>
      </div>
    );
  }
  return (
    <div className='bg-white'>
      <div>
        {/* Mobile filter dialog */}
        <MobileFilters />
        {/*  */}
        {/* Breadcrumb */}
        <Breadcrumb category={category} subcategory={subcategory} />
        {/*  */}

        <main className='mx-auto max-w-2xl px-4 lg:max-w-7xl lg:px-8'>
          <div className='border-b border-gray-200 pb-10 pt-24'>
            <h1 className='text-4xl font-bold tracking-tight text-gray-900'>
              Προϊόντα
            </h1>
            {/* <p className='mt-4 text-base text-gray-500'>
              Lorem ipsum dolor sit amet consectetur adipisicing.
            </p> */}
          </div>

          <div className='pb-24 pt-12 lg:grid lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4'>
            {/* Filters */}
            <Filters />
            {/*  */}

            <section
              aria-labelledby='product-heading'
              className='mt-6 lg:col-span-2 lg:mt-0 xl:col-span-3'
            >
              <h2 id='product-heading' className='sr-only'>
                Products
              </h2>

              {/* ProductList */}
              <ProductList />

              {/*  */}
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ProductsPage;
