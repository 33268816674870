import React from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import { useProductsContext } from '../context/products_context';
import { useFilterContext } from '../context/filter_context';
import { getUniqueValues } from '../utils/helpers';
import { CiSearch } from 'react-icons/ci';
import { SlTrash } from 'react-icons/sl';

const Filters = () => {
  const {
    filters: { text, company },
    updateFilters,
    all_products,
    clearFilters,
  } = useFilterContext();

  const companies = getUniqueValues(all_products, 'company');

  const { openMobileFilters } = useProductsContext();
  return (
    <aside>
      <h2 className='sr-only'>Filters</h2>

      <button
        type='button'
        onClick={openMobileFilters}
        className='inline-flex items-center lg:hidden'
      >
        <span className='text-sm font-medium text-gray-700'>Filters</span>
        <PlusIcon
          aria-hidden='true'
          className='ml-1 h-5 w-5 flex-shrink-0 text-gray-400'
        />
      </button>

      <div className='hidden lg:block'>
        <form
          className='space-y-10 divide-y divide-gray-200'
          onSubmit={(e) => e.preventDefault()}
        >
          <div>
            <label
              htmlFor='search'
              className='block text-sm/6 font-medium text-gray-900'
            >
              Αναζήτηση
            </label>
            <div className='relative mt-2 flex items-center'>
              <input
                name='text'
                type='text'
                value={text}
                placeholder='Αναζήτηση'
                onChange={updateFilters}
                className='block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-400 sm:text-sm/6'
              />
              <div className='absolute inset-y-0 right-0 flex py-1.5 pr-1.5'>
                <kbd className='inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400'>
                  <CiSearch />
                </kbd>
              </div>
            </div>
          </div>

          <div className='pt-10'>
            <fieldset>
              <legend className='block text-sm font-medium text-gray-900'>
                <span>Εταιρεία</span>
              </legend>
              {companies.map((c, index) => (
                <div className='space-y-3 pt-6'>
                  <div key={index} className='flex items-center'>
                    <input
                      name='company'
                      id={index}
                      type='radio'
                      value={c}
                      checked={company === c}
                      onChange={updateFilters}
                      className='h-4 w-4 rounded border-gray-300 text-amber-400 focus:ring-amber-400'
                    />
                    <label htmlFor={c} className='ml-3 text-sm text-gray-600'>
                      {c}
                    </label>
                  </div>
                </div>
              ))}
            </fieldset>
          </div>
          <button
            type='button'
            className='inline-flex items-center gap-x-1.5 rounded-md bg-amber-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600'
            onClick={clearFilters}
          >
            <SlTrash aria-hidden='true' className='-ml-0.5 size-5' />
            Εκκαθάριση
          </button>
        </form>
      </div>
    </aside>
  );
};

export default Filters;
