import React from 'react';
import { Link } from 'react-router-dom';
const ErrorPage = () => {
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
      <main className='grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8'>
        <div className='text-center'>
          <p className='text-base font-semibold text-amber-400'>404</p>
          <h1 className='mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
            Η Σελίδα δεν υφήστατε!
          </h1>
          <p className='mt-6 text-base leading-7 text-gray-600'>
            Λυπούμαστε, δεν μπορέσαμε να βρούμε αποτελέσματα για την αναζήτηση
            σας.
          </p>
          <div className='mt-10 flex items-center justify-center gap-x-6'>
            <Link
              to={'/'}
              className='rounded-md bg-amber-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-amber-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-400'
            >
              Επσιτροφή στην Αρχική
            </Link>
          </div>
        </div>
      </main>
    </>
  );
};

export default ErrorPage;
