import React, { useContext, useEffect, useReducer } from 'react';
import reducer from '../reducers/products_reducer';
import axios from 'axios';
//import { products_url as url } from '../utils/constants';
import { navigation } from '../utils/constants';
import {
  SIDEBAR_OPEN,
  SIDEBAR_CLOSE,
  MOBILE_FILTERS_SIDEBAR_OPEN,
  MOBILE_FILTERS_SIDEBAR_CLOSE,
  GET_PRODUCTS_BEGIN,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_FEATURE_PRODUCTS_BEGIN,
  GET_FEATURE_PRODUCTS_SUCCESS,
  GET_FEATURE_PRODUCTS_ERROR,
  GET_SINGLE_PRODUCT_BEGIN,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_ERROR,
  GET_CATEGORIES_BEGIN,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_ERROR,
} from '../actions';

const url = process.env.REACT_APP_PRODUCTS_URL;

const initialState = {
  isSidebarOpen: false,
  isMobileFiltersSidebarOpen: false,
  products_loading: false,
  products_error: false,
  products: [],
  featureProducts: [],
  feature_products_loading: false,
  feature_products_error: false,
  featured_products: [],
  single_product_loading: false,
  single_product_error: false,
  single_product: {},
  categories: [],
  categories_loading: false,
  categories_error: false,
};

const ProductsContext = React.createContext();

export const ProductsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const openSidebar = () => {
    dispatch({ type: SIDEBAR_OPEN });
  };
  const closeSidebar = () => {
    dispatch({ type: SIDEBAR_CLOSE });
  };

  const openMobileFilters = () => {
    dispatch({ type: MOBILE_FILTERS_SIDEBAR_OPEN });
  };
  const closeMobileFilters = () => {
    dispatch({ type: MOBILE_FILTERS_SIDEBAR_CLOSE });
  };

  const fetchCategories = async () => {
    dispatch({ type: GET_CATEGORIES_BEGIN });
    try {
      const response = await axios.get(`${url}categories`);
      const categories = response.data.categories;
      navigation.categories[0].sections.push(...categories);
      dispatch({ type: GET_CATEGORIES_SUCCESS, payload: navigation });
    } catch (error) {
      dispatch({ type: GET_CATEGORIES_ERROR });
    }
  };

  const fetchProducts = async (url) => {
    dispatch({ type: GET_PRODUCTS_BEGIN });
    try {
      const response = await axios.get(url);
      const products = response.data.products;
      dispatch({ type: GET_PRODUCTS_SUCCESS, payload: products });
    } catch (error) {
      dispatch({ type: GET_PRODUCTS_ERROR });
    }
  };

  const fetchFeatureProducts = async () => {
    dispatch({ type: GET_FEATURE_PRODUCTS_BEGIN });
    try {
      const response = await axios.get(`${url}feature`);
      const products = response.data.products;
      dispatch({ type: GET_FEATURE_PRODUCTS_SUCCESS, payload: products });
    } catch (error) {
      dispatch({ type: GET_FEATURE_PRODUCTS_ERROR });
    }
  };

  const fetchSingleProduct = async (url) => {
    dispatch({ type: GET_SINGLE_PRODUCT_BEGIN });
    try {
      const response = await axios.get(url);
      const singleProduct = response.data.product;
      dispatch({ type: GET_SINGLE_PRODUCT_SUCCESS, payload: singleProduct });
    } catch (error) {
      dispatch({ type: GET_SINGLE_PRODUCT_ERROR });
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchFeatureProducts();
  }, []);
  return (
    <ProductsContext.Provider
      value={{
        ...state,
        openSidebar,
        closeSidebar,
        openMobileFilters,
        closeMobileFilters,
        fetchSingleProduct,
        fetchProducts,
        fetchCategories,
        fetchFeatureProducts,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};
// make sure use
export const useProductsContext = () => {
  return useContext(ProductsContext);
};
