import React from 'react';
import { Link } from 'react-router-dom';
//const breadcrumbs = [{ id: 1, name: 'Αρχική', href: '/' }];

const Breadcrumb = ({ title, product, category, subcategory }) => {
  return (
    <div className='border-b border-gray-200'>
      <nav
        aria-label='Breadcrumb'
        className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'
      >
        <ol role='list' className='flex items-center space-x-4 py-4'>
          <li>
            <div className='flex items-center'>
              <Link to={'/'} className='mr-4 text-sm font-medium text-gray-900'>
                Αρχική
              </Link>
              <svg
                viewBox='0 0 6 20'
                aria-hidden='true'
                className='h-5 w-auto text-gray-300'
              >
                <path
                  d='M4.878 4.34H3.551L.27 16.532h1.327l3.281-12.19z'
                  fill='currentColor'
                />
              </svg>
            </div>
          </li>
          {product && (
            <>
              <li>
                <div className='flex items-center'>
                  <span className='mr-4 text-sm font-medium text-gray-900'>
                    Προϊόντα
                  </span>
                  <svg
                    viewBox='0 0 6 20'
                    aria-hidden='true'
                    className='h-5 w-auto text-gray-300'
                  >
                    <path
                      d='M4.878 4.34H3.551L.27 16.532h1.327l3.281-12.19z'
                      fill='currentColor'
                    />
                  </svg>
                </div>
              </li>
              <li>
                <div className='flex items-center'>
                  <Link
                    to={`/products/${product.category}/${product.subcategory}`}
                    className='mr-4 text-sm font-medium text-gray-900'
                  >
                    {product.subcategory}
                  </Link>
                  <svg
                    viewBox='0 0 6 20'
                    aria-hidden='true'
                    className='h-5 w-auto text-gray-300'
                  >
                    <path
                      d='M4.878 4.34H3.551L.27 16.532h1.327l3.281-12.19z'
                      fill='currentColor'
                    />
                  </svg>
                </div>
              </li>

              <li className='text-sm'>{title}</li>
            </>
          )}

          {
            (category,
            category && (
              <>
                <li>
                  <div className='flex items-center'>
                    <span className='mr-4 text-sm font-medium text-gray-900'>
                      Προϊόντα
                    </span>
                    <svg
                      viewBox='0 0 6 20'
                      aria-hidden='true'
                      className='h-5 w-auto text-gray-300'
                    >
                      <path
                        d='M4.878 4.34H3.551L.27 16.532h1.327l3.281-12.19z'
                        fill='currentColor'
                      />
                    </svg>
                  </div>
                </li>
                <li>
                  <div className='flex items-center'>
                    <span className='mr-4 text-sm text-gray-900'>
                      {category}
                    </span>

                    <svg
                      viewBox='0 0 6 20'
                      aria-hidden='true'
                      className='h-5 w-auto text-gray-300'
                    >
                      <path
                        d='M4.878 4.34H3.551L.27 16.532h1.327l3.281-12.19z'
                        fill='currentColor'
                      />
                    </svg>
                  </div>
                </li>

                <li className='text-sm'>{subcategory}</li>
              </>
            ))
          }
          {/* <li className='text-sm'>
            <Link
              to='#'
              aria-current='page'
              className='font-medium text-gray-500 hover:text-gray-600'
            >
              Προϊόντα
            </Link>
          </li> */}
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumb;
