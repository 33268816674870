import React from 'react';
import { FiTruck, FiTool, FiUsers, FiSettings } from 'react-icons/fi';
import { VscWorkspaceTrusted } from 'react-icons/vsc';
import { GiCrane } from 'react-icons/gi';
import { TbCrane } from 'react-icons/tb';
import { GiConcreteBag } from 'react-icons/gi';
import { GiPowerGenerator } from 'react-icons/gi';
import { PiCrane } from 'react-icons/pi';

const features = [
  {
    name: 'Ενοικίαση Ειδικού Εξοπλισμού',
    description:
      'Από μικρά εργαλεία χειρός έως βαριά μηχανήματα, διαθέτουμε όλα όσα χρειάζεστε για κάθε είδους έργο.',
    icon: GiCrane,
  },
  {
    name: 'Ποιότητα και Αξιοπιστία',
    description:
      'Όλα τα μηχανήματα μας ελέγχονται τακτικά για να εξασφαλίσουμε την ασφάλεια και την αποτελεσματικότητά τους.',
    icon: VscWorkspaceTrusted,
  },
  {
    name: 'Άμεση Παράδοση',
    description:
      'Προσφέρουμε γρήγορη και αξιόπιστη παράδοση του εξοπλισμού στον χώρο σας, ώστε να ξεκινήσετε το έργο σας χωρίς καθυστέρηση.',
    icon: FiTruck,
  },
  {
    name: 'Τεχνική Υποστήριξη',
    description:
      'Είμαστε εδώ για να σας παρέχουμε πλήρη τεχνική υποστήριξη καθ’ όλη τη διάρκεια της ενοικίασης.',
    icon: FiSettings,
  },
];

const category = [
  {
    name: 'Εκσκαφείς & Φορτωτές',
    description:
      'Ιδανικοί για ανασκαφές, χωματουργικές εργασίες και κατασκευές.',
    icon: PiCrane,
  },
  {
    name: 'Γερανοί & Ανυψωτικά',
    description: 'Για τη μεταφορά βαρέων φορτίων με ασφάλεια και ακρίβεια.',
    icon: TbCrane,
  },
  {
    name: 'Μπετονιέρες & Αντλίες Σκυροδέματος',
    description: 'Για έργα που απαιτούν ποιοτικό και γρήγορη σκυροδέτηση.',
    icon: GiConcreteBag,
  },
  {
    name: 'Γεννήτριες & Αντλίες Νερού',
    description:
      'Για να εξασφαλίσετε την απρόσκοπτη λειτουργία του εργοταξίου σας.',
    icon: GiPowerGenerator,
  },
];

const ServicesPage = () => {
  return (
    <div className='bg-white py-24 sm:py-32'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto max-w-2xl lg:text-center'>
          <h2 className='text-base font-semibold leading-7 text-amber-400'>
            Υπηρεσίες
          </h2>
          <p className='mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl lg:text-balance'>
            Ανακαλύψτε τις υπηρεσίες και τον εξοπλισμό μας.
          </p>
          <p className='mt-6 text-lg leading-8 text-gray-600'>
            Επικοινωνήστε μαζί μας σήμερα για να μάθετε περισσότερα ή να λάβετε
            προσφορά προσαρμοσμένη στις ανάγκες σας!
          </p>
        </div>
        <div className='mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl'>
          <h2 className='text-lg font-semibold leading-7 text-amber-400 mb-5'>
            Τι Προσφέρουμε;
          </h2>
          <dl className='grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16'>
            {features.map((feature) => (
              <div key={feature.name} className='relative pl-16'>
                <dt className='text-base font-semibold leading-7 text-gray-900'>
                  <div className='absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-amber-400'>
                    <feature.icon
                      aria-hidden='true'
                      className='h-6 w-6 text-white'
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className='mt-2 text-base leading-7 text-gray-600'>
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
        <div className='mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl'>
          <h2 className='text-lg font-semibold leading-7 text-amber-400 mb-5'>
            Κατηγορίες Εξοπλισμού
          </h2>
          <dl className='grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16'>
            {category.map((feature) => (
              <div key={feature.name} className='relative pl-16'>
                <dt className='text-base font-semibold leading-7 text-gray-900'>
                  <div className='absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-amber-400'>
                    <feature.icon
                      aria-hidden='true'
                      className='h-6 w-6 text-white'
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className='mt-2 text-base leading-7 text-gray-600'>
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
        <div className='mx-auto max-w-2xl lg:text-center mt-20'>
          <h2 className='text-lg font-semibold leading-7 text-amber-400 mb-5'>
            Γιατί να μας Επιλέξετε;
          </h2>
          <p className='mt-6 text-lg leading-8 text-gray-600'>
            Με την εμπειρία και την τεχνογνωσία μας στον χώρο της κατασκευής,
            δεσμευόμαστε να προσφέρουμε εξοπλισμό που καλύπτει τις ανάγκες του
            κάθε πελάτη. Είτε πρόκειται για μικρές ανακαινίσεις είτε για μεγάλα
            κατασκευαστικά έργα, έχουμε τη λύση που σας ταιριάζει.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServicesPage;
