import React from 'react';
import { Link } from 'react-router-dom';

const Product = ({ _id, title, description, details, images }) => {
  const product = {
    _id,
    title,
    description,
    details,
    images,
  };
  return (
    <div
      key={product.id}
      className='group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white'
    >
      <div className='aspect-h-4 aspect-w-3 bg-gray-50 sm:aspect-none group-hover:opacity-75 sm:h-96'>
        <img
          alt={product.title}
          src={product.images[0].url}
          className='h-ful w-full object-scale-down object-center sm:h-full sm:w-full'
        />
      </div>
      <div className='flex flex-1 flex-col space-y-2 p-4'>
        <h3 className='text-sm font-medium text-gray-900'>
          <Link to={`/products/${_id}`}>
            <span aria-hidden='true' className='absolute inset-0' />
            {product.title}
          </Link>
        </h3>
        <p className='text-sm text-gray-500'>
          {product.description.substring(0, 50)}...
        </p>
        <div className='flex flex-1 flex-col justify-end'>
          {/* <p className='text-sm italic text-gray-500'>{product.options}</p> */}
          {/* <p className='text-base font-medium text-gray-900'>
            &#8364;{product.price}/εβδ.
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Product;
