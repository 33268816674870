export let navigation = {
  categories: [
    {
      id: 'products',
      name: 'Προϊόντα',
      sections: [],
    },
  ],
  pages: [
    { name: 'Υπηρεσίες', href: '/services' },
    { name: 'Εταιρεία', href: '/company' },
    { name: 'Επικοινωνία', href: '/contact' },
  ],
};

//export const products_url = 'http://localhost:5000/api/v1/products/';
