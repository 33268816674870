/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import {
  BuildingOffice2Icon,
  EnvelopeIcon,
  PhoneIcon,
} from '@heroicons/react/24/outline';
import { ContactForm } from '../components';

const ContactPage = () => {
  return (
    <div className='relative isolate bg-white'>
      <div className='mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2'>
        <div className='relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48'>
          <div className='mx-auto max-w-xl lg:mx-0 lg:max-w-lg'>
            <div className='absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2'>
              <svg
                aria-hidden='true'
                className='absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]'
              >
                <defs>
                  <pattern
                    x='100%'
                    y={-1}
                    id='83fd4e5a-9d52-42fc-97b6-718e5d7ee527'
                    width={200}
                    height={200}
                    patternUnits='userSpaceOnUse'
                  >
                    <path d='M130 200V.5M.5 .5H200' fill='none' />
                  </pattern>
                </defs>
                <rect fill='white' width='100%' height='100%' strokeWidth={0} />
                <svg x='100%' y={-1} className='overflow-visible fill-gray-50'>
                  <path d='M-470.5 0h201v201h-201Z' strokeWidth={0} />
                </svg>
                <rect
                  fill='url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)'
                  width='100%'
                  height='100%'
                  strokeWidth={0}
                />
              </svg>
            </div>
            <h2 className='text-3xl font-bold tracking-tight text-gray-900'>
              Υποστήριξη πελατών
            </h2>
            <p className='mt-6 text-lg leading-8 text-gray-600'>
              Θα χαρούμε να σας εξυπηρετήσουμε και να βρούμε λύσεις
              προσαρμοσμένες στις ανάγκες σας. Επικοινωνήστε μαζί μας για
              διαθεσιμότητα, τιμές και περισσότερες πληροφορίες.
            </p>
            <dl className='mt-10 space-y-4 text-base leading-7 text-gray-600'>
              <div className='flex gap-x-4'>
                <dt className='flex-none'>
                  <span className='sr-only'>Διεύθυνση</span>
                  <BuildingOffice2Icon
                    aria-hidden='true'
                    className='h-7 w-6 text-gray-400'
                  />
                </dt>
                <dd>
                  Νέος Ζυγός,
                  <br />
                  Ξάνθη - Ελλάδα
                  <br />
                  (Περιφερειακή Καβάλας)
                </dd>
              </div>
              <div className='flex gap-x-4'>
                <dt className='flex-none'>
                  <span className='sr-only'>Τηλέφωνο</span>
                  <PhoneIcon
                    aria-hidden='true'
                    className='h-7 w-6 text-gray-400'
                  />
                </dt>
                <dd>
                  <a href='tel:+30 2541 305476' className='hover:text-gray-900'>
                    +30 2541 305476
                  </a>
                </dd>
              </div>
              {/* <div className='flex gap-x-4'>
                <dt className='flex-none'>
                  <span className='sr-only'>Email</span>
                  <EnvelopeIcon
                    aria-hidden='true'
                    className='h-7 w-6 text-gray-400'
                  />
                </dt>
                <dd>
                  <a
                    href='mailto:hello@example.com'
                    className='hover:text-gray-900'
                  >
                    hello@example.com
                  </a>
                </dd>
              </div> */}
            </dl>
          </div>
        </div>
        <ContactForm />
      </div>
    </div>
  );
};

export default ContactPage;
