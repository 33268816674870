'use client';
import aboutImage from '../assets/logo.jpg';

const AboutPage = () => {
  return (
    <div className='bg-white'>
      <main className='isolate'>
        {/* Image section */}
        <div className='mt-32 sm:mt-20 xl:mx-auto xl:max-w-7xl xl:px-8'>
          <img
            alt='logo'
            src={aboutImage}
            className='aspect-[5/2] w-full object-cover xl:rounded-3xl'
          />
        </div>

        {/* Values section */}
        <div className='mx-auto mt-32 max-w-7xl px-6 sm:mt-20 lg:px-8'>
          <div className='max-w-2xl place-self-center text-center'>
            <h2 className='text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl'>
              Η Εταιρεία μας
            </h2>
            <h3 className='text-pretty mt-2 text-lg font-semibold tracking-tight text-gray-900 sm:text-lg'>
              Ενοικιάστε αξιόπιστα εργαλεία και μηχανήματα για το έργο σας
            </h3>
          </div>
          <div className='mx-auto max-w-2xl'>
            <p className='mt-6 mb-6 text-lg leading-8 text-gray-600 text-center'>
              Καλωσορίσατε στην κορυφαία επιλογή σας για ενοικίαση εξοπλισμού
              κατασκευών! Είμαστε υπερήφανοι που παρέχουμε μια εκτενή γκάμα από
              υψηλής ποιότητας μηχανήματα και εξοπλισμό για κατασκευαστικές
              εργασίες, προσφέροντας εξειδικευμένες λύσεις για επαγγελματίες και
              ιδιώτες. Από ισχυρούς εκσκαφείς μέχρι μηχανήματα διάτρησης και
              σύγχρονα εργαλεία μπαταρίας, όπως τροχούς και κρουστικά δράπανα –
              διαθέτουμε μια μεγάλη γκάμα εξοπλισμού υψηλής ποιότητας. Με
              ευέλικτους όρους ενοικίασης και εξειδικευμένη υποστήριξη, είμαστε
              δίπλα σας σε κάθε έργο.
            </p>
          </div>
        </div>
      </main>
    </div>
  );
};
export default AboutPage;
